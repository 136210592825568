import React from 'react';
import GroupForm from './GroupLeaderForm';

const Registration = () => {
    return (
        <GroupForm/>
    );
};

export default Registration;
